import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { forgot_password } from "../../../../context/actions/userAction"; // Update with your actual action
import logo from "../../../../assets/icons/gadiRent-Logo-New.png";
import "./AdminSignIn.css";

const ForgotPassword = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [message, setMessage] = useState("");

  const formik = useFormik({
    initialValues: { email: "" },
    validationSchema: Yup.object({
      email: Yup.string().email("Invalid email format").required("Email is required"),
    }),
    onSubmit: async (values) => {
      setIsSubmitting(true); // Indicate loading state
      setMessage(""); // Clear previous messages
      try {
        await dispatch(forgot_password(1, { email: values.email }, navigate));
        
        setMessage("Password reset link sent to your email.");
      } catch (error) {
        console.error("Error:", error);
        setMessage("Failed to send reset email. Please try again.");
      }
      setIsSubmitting(false);
    },
    
  });

  return (
    <div className="auth-wrapper">
      <div>
        <img src={logo} alt="logo" className="auth-logo" />
      </div>
      <div className="auth-inner">
        <h3>Forgot Password</h3>
        {message && <div className="alert alert-info">{message}</div>}
        <form onSubmit={formik.handleSubmit}>
          <div className="mb-3">
            <label className="form-label">Email Address</label>
            <input
              type="email"
              className={`form-control ${formik.touched.email && formik.errors.email ? "is-invalid" : ""}`}
              name="email"
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            {formik.touched.email && formik.errors.email && (
              <div className="invalid-feedback">{formik.errors.email}</div>
            )}
          </div>
          <div className="d-grid">
            <button type="submit" className="btn btn-primary" disabled={isSubmitting}>
              {isSubmitting ? "Sending..." : "Submit"}
            </button>
          </div>
          <div className="text-center mt-3">
            <button type="button" className="btn btn-link" onClick={() => navigate("/admin/login")}>
              Back to Login
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ForgotPassword;
