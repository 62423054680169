import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
// import { verifyOTP, resendOTP } from "../../../../context/actions/userAction";
import "./OtpScreen.css";
import { VerifyOtp } from "../../../../context/actions/userAction";

const OtpScreen = () => {
  const location = useLocation();
  const email = location.state?.email || "";
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [message, setMessage] = useState("");
  const [timer, setTimer] = useState(60);
  const [canResend, setCanResend] = useState(false);

  // Countdown Timer for Resend OTP
  useEffect(() => {
    let interval;
    if (timer > 0) {
      interval = setInterval(() => {
        setTimer((prev) => prev - 1);
      }, 1000);
    } else {
      setCanResend(true);
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [timer]);

  const formik = useFormik({
    initialValues: { otp: "" },
    validationSchema: Yup.object({
      otp: Yup.string()
        .matches(/^\d{4,6}$/, "OTP must be 4 or 6 digits")
        .required("OTP is required"),
    }),
    onSubmit: async (values) => {
      setIsSubmitting(true);
      try {
        const response = await dispatch(VerifyOtp("user", { email, otp: values.otp }, navigate)); 
        if (response?.success) {
          setMessage("OTP Verified! Redirecting...");
          navigate("/reset-password"); // Redirecting to reset password screen
        } else {
          setMessage(response?.message || "Invalid OTP. Try again.");
        }
      } catch (error) {
        setMessage("Failed to verify OTP. Please try again.");
      }
      setIsSubmitting(false);
    },
    
  });

  const handleResendOTP = async () => {
    setCanResend(false);
    setTimer(60);
    setMessage("Resending OTP...");
    try {
    //   await dispatch(resendOTP());
      setMessage("OTP Resent Successfully!");
    } catch (error) {
      setMessage("Failed to resend OTP.");
    }
  };

  return (
    <div className="otp-wrapper">
      <div className="otp-inner">
        <h3>OTP Verification</h3>
        {message && <div className="alert alert-info">{message}</div>}
        <form onSubmit={formik.handleSubmit}>
          <div className="mb-3">
            <label className="form-label">Enter OTP</label>
            <input
              type="text"
              className={`form-control ${formik.touched.otp && formik.errors.otp ? "is-invalid" : ""}`}
              name="otp"
              maxLength="6"
              value={formik.values.otp}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            {formik.touched.otp && formik.errors.otp && (
              <div className="invalid-feedback">{formik.errors.otp}</div>
            )}
          </div>
          <div className="d-grid">
            <button type="submit" className="btn btn-primary" disabled={isSubmitting}>
              {isSubmitting ? "Verifying..." : "Verify OTP"}
            </button>
          </div>
          <div className="text-center mt-3">
            {canResend ? (
              <button type="button" className="btn btn-link" onClick={handleResendOTP}>
                Resend OTP
              </button>
            ) : (
              <span className="otp-timer">Resend OTP in {timer}s</span>
            )}
          </div>
        </form>
      </div>
    </div>
  );
};

export default OtpScreen;
