import React from "react";
import PublicLayout from "../components/layouts/PublicLayout";
// import Home from "../modules/home/Home";
import AdminSignUP from "../modules/authentication/admin/adminSignUp/AdminSignUp";
import AdminSignIn from "../modules/authentication/admin/adminSignIn/AdminSignIn";
import Login from "../modules/authentication/admin/adminSignIn/AdminSignIn";
import LandingPage from "../modules/LandingPage/LandingPage";
import OtpScreen from "../modules/authentication/admin/ForgotScreen/OtpScreen";
import ForgotPassword from "../modules/authentication/admin/ForgotScreen/ForgotPassword";
import ResetPassword from "../modules/authentication/admin/ForgotScreen/resetPassword";


const publicRoutes = [
	{
		path: "/",
		exact: true,
		layout: PublicLayout,
		element: <PublicLayout><LandingPage/></PublicLayout> 
	},
	{
		path: "/admin/login",
		exact: true,
		layout: PublicLayout,
		element: <PublicLayout><AdminSignIn/></PublicLayout> 
	},
	{
		path: "/admin/signUp",
		exact: true,
		layout: PublicLayout,
		element: <PublicLayout><AdminSignUP/></PublicLayout>
	},
	
	{
		path: "/login",
		exact: true,
		layout: PublicLayout,
		element: <PublicLayout><Login/></PublicLayout>
	},
	{
		path: "/forgot-password",
		exact: true,
		layout: PublicLayout,
		element: <PublicLayout><ForgotPassword/></PublicLayout>
	},
	{
		path: "/Otp-Screen",
		exact: true,
		layout: PublicLayout,
		element: <PublicLayout><OtpScreen/></PublicLayout>
	},
	{
		path: "/reset-password",
		exact: true,
		layout: PublicLayout,
		element: <PublicLayout><ResetPassword/></PublicLayout>
	},
	
];
export default publicRoutes;
