import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { reset_password } from "../../../../context/actions/userAction"; // API action
import logo from "../../../../assets/icons/gadiRent-Logo-New.png";
import "./AdminSignIn.css";

const ResetPassword = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [isSubmitting, setIsSubmitting] = useState(false);

  // Extract token from URL params
  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get("token");

  const formik = useFormik({
    initialValues: {
      newPassword: "",
      confirmPassword: "",
    },
    validationSchema: Yup.object({
      newPassword: Yup.string()
        .min(6, "Password must be at least 6 characters")
        .required("New password is required"),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref("newPassword")], "Passwords must match")
        .required("Confirm password is required"),
    }),
    onSubmit: async (values) => {
      setIsSubmitting(true);
      try {
        // Pass password and confirmPassword as payload
        await dispatch(
          reset_password({ password: values.newPassword, confirmPassword: values.confirmPassword }, navigate)
        );
      } catch (error) {
        console.error("Reset Password Error:", error);
      }
      setIsSubmitting(false);
    },
  });

  return (
    <div className="auth-wrapper">
      <div>
        <img src={logo} alt="logo" className="auth-logo" />
      </div>
      <div className="auth-inner">
        <h3>Reset Password</h3>
        <form onSubmit={formik.handleSubmit}>
          <div className="mb-3">
            <label className="form-label">New Password</label>
            <input
              type="password"
              className={`form-control ${formik.touched.newPassword && formik.errors.newPassword ? "is-invalid" : ""}`}
              name="newPassword"
              value={formik.values.newPassword}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            {formik.touched.newPassword && formik.errors.newPassword && (
              <div className="invalid-feedback">{formik.errors.newPassword}</div>
            )}
          </div>

          <div className="mb-3">
            <label className="form-label">Confirm Password</label>
            <input
              type="password"
              className={`form-control ${formik.touched.confirmPassword && formik.errors.confirmPassword ? "is-invalid" : ""}`}
              name="confirmPassword"
              value={formik.values.confirmPassword}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            {formik.touched.confirmPassword && formik.errors.confirmPassword && (
              <div className="invalid-feedback">{formik.errors.confirmPassword}</div>
            )}
          </div>

          <div className="d-grid">
            <button type="submit" className="btn btn-primary" disabled={isSubmitting}>
              {isSubmitting ? "Resetting..." : "Reset Password"}
            </button>
          </div>
          <div className="text-center mt-3">
            <button type="button" className="btn btn-link" onClick={() => navigate("/admin/login")}>
              Back to Login
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ResetPassword;
