import React from 'react';
import './styles.css';

function LandingPage() {
  return (
    <div className="App">
      {/* Header Section */}
      <header>
        <div className="container">
          <div className="logo">
            <h1>GadiRent</h1>
          </div>
          <nav>
            <ul>
              <li><a href="#home">Home</a></li>
              <li><a href="#how-it-works">How It Works</a></li>
              <li><a href="#features">Features</a></li>
              <li><a href="#pricing">Pricing</a></li>
              <li><a href="#contact" className="btn-primary">Contact Us</a></li>
              <li><a href="/login" className="btn-primary" target="_blank" rel="noopener noreferrer">Get Started</a></li>
            </ul>
          </nav>
        </div>
      </header>

      {/* Hero Section */}
      <section id="home" className="hero">
        <div className="container">
          <div className="hero-content animate__animated animate__fadeInUp">
            <h1>List Your Vehicle with Ease</h1>
            <p>Join thousands of vehicle owners and renters on our platform. Earn money by renting out your vehicle.</p>
            <div className="cta-buttons">
              <a href="#signup" className="btn-primary">List Your Vehicle</a>
            </div>
          </div>
        </div>
      </section>

      {/* How It Works Section */}
      <section id="how-it-works" className="how-it-works">
        <div className="container">
          <h2 className="animate__animated animate__fadeIn">How It Works</h2>
          <div className="steps">
            <div className="step animate__animated animate__fadeInLeft">
              <i className="fas fa-car fa-3x"></i>
              <h3>List Your Vehicle</h3>
              <p>Sign up and add your vehicle details. Set your own rental price and availability.</p>
            </div>
            <div className="step animate__animated animate__fadeInUp">
              <i className="fas fa-calendar-check fa-3x"></i>
              <h3>Get Booked</h3>
              <p>Renters can find and book your vehicle for their desired dates.</p>
            </div>
            <div className="step animate__animated animate__fadeInRight">
              <i className="fas fa-money-bill-wave fa-3x"></i>
              <h3>Earn Money</h3>
              <p>Get paid securely for every rental. Enjoy hassle-free transactions.</p>
            </div>
          </div>
        </div>
      </section>

      {/* Features Section */}
      <section id="features" className="features">
        <div className="container">
          <h2 className="animate__animated animate__fadeIn">Why Choose Us?</h2>
          <div className="feature-list">
            <div className="feature animate__animated animate__fadeInLeft">
              <i className="fas fa-shield-alt fa-3x"></i>
              <h3>Secure Transactions</h3>
              <p>We ensure all payments are safe and secure.</p>
            </div>
            <div className="feature animate__animated animate__fadeInUp">
              <i className="fas fa-user-friends fa-3x"></i>
              <h3>Verified Users</h3>
              <p>All renters and owners are verified for your safety.</p>
            </div>
            <div className="feature animate__animated animate__fadeInRight">
              <i className="fas fa-headset fa-3x"></i>
              <h3>24/7 Support</h3>
              <p>Our support team is always here to help you.</p>
            </div>
          </div>
        </div>
      </section>

      {/* Pricing Section */}
      <section id="pricing" className="pricing">
        <div className="container">
          <h2 className="animate__animated animate__fadeIn">Get Started Today</h2>
          <p className="subtitle">Join our platform and start earning by renting out your vehicle. No hidden fees, no complicated pricing—just simple and transparent.</p>
          <div className="pricing-plans">
            <div className="plan animate__animated animate__fadeInUp">
              <h3>Why Choose Us?</h3>
              <ul>
                <li><i className="fas fa-check"></i> No upfront costs</li>
                <li><i className="fas fa-check"></i> Transparent earnings</li>
                <li><i className="fas fa-check"></i> Flexible rental options</li>
                <li><i className="fas fa-check"></i> 24/7 support for owners</li>
              </ul>
              <a href="#signup" className="btn-primary">List Your Vehicle</a>
            </div>
          </div>
        </div>
      </section>

      {/* Contact Section */}
      <section id="contact" className="contact">
        <div className="container">
          <h2 className="animate__animated animate__fadeIn">Contact Us</h2>
          <p>Have questions? Reach out to us!</p>
          <form className="animate__animated animate__fadeInUp">
            <input type="text" placeholder="Your Name" required />
            <input type="email" placeholder="Your Email" required />
            <textarea placeholder="Your Message" required></textarea>
            <button type="submit" className="btn-primary">Send Message</button>
          </form>
        </div>
      </section>

      {/* Footer Section */}
      <footer>
        <div className="container">
          <p>&copy; 2025 GadiRent. All rights reserved.</p>
          <div className="social-links">
            <a href="#"><i className="fab fa-facebook"></i></a>
            <a href="#"><i className="fab fa-twitter"></i></a>
            <a href="#"><i className="fab fa-instagram"></i></a>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default LandingPage;